import React, { useState, useEffect } from 'react';

function truncateString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength) + '...';
}

export default function WritingPrompt() {
  const [stage, setStage] = useState('select'); // select, write, evaluate
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [response, setResponse] = useState('');
  const [timer, setTimer] = useState(1800); // 30 minutes in seconds
  const [score, setScore] = useState(null);
  const [commentary, setCommentary] = useState('');
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (stage === 'write' && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      handleSubmit();
    }
  }, [stage, timer]);
 
  // Fetch topics from JSON file on component mount
  useEffect(() => {
    fetch('/topics.json')
      .then(response => response.json())
      .then(data => {
        setTopics(data);
      })
      .catch(error => console.error('Error loading topics:', error));
  }, []);

  const handleSelectTopic = (event) => {
    const selectedIndex = event.target.value;
    setSelectedTopic(topics[selectedIndex].Topic);
    setSelectedTask(topics[selectedIndex].Task);
    setStage('write');
  };

  const handleResponseChange = (event) => {
    setResponse(event.target.value);
  };

  const handleSubmit = () => {
    setStage('evaluate');
    const data_to_send = {
      topic: selectedTopic,
      task: selectedTask,
      response: response
    };
    //console.log(JSON.stringify(data_to_send))
    // POST request using fetch
    fetch('https://2edc1yb4if.execute-api.us-east-1.amazonaws.com/Test', {
      method: 'POST', // Specify the request method as POST
      headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data_to_send) // Convert the data object to a JSON string
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const parsedData = JSON.parse(data.body);
        setScore(Number(parsedData.Rating));
        setCommentary(parsedData.Commentary);
      })
      .catch(error => {
        setError(error);
      });
  };

  if (stage === 'select') {
    return (
      <div className="writing-prompt">
        <label>Select a topic to practice: </label>
        <select onChange={handleSelectTopic}>
          <option value="">--Choose a topic--</option>
          {topics.map((topicItem, index) => (
            <option key={index} value={index}>{index+1}: {truncateString(topicItem.Topic,100)}</option>
          ))}
        </select>
      </div>
    );
  }

  if (stage === 'write') {
    return (
      <div className="writing-prompt">
        <h3>Topic: {selectedTopic}</h3>
        <h4>Task: {selectedTask}</h4>
        <div>Time left: {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}</div>
        <textarea placeholder="Type your response here..." value={response} onChange={handleResponseChange}></textarea>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    );
  }

  if (stage === 'evaluate') {
    return (
      <div className="writing-prompt">
        <p id="your_response">Your response:</p>
        <p>{response}</p>
        {score !== null ? (
          <>
            <p id="score">Score: {score} out of 6</p>
            <p id="feedback">Feedback:</p>
            <p>{commentary}</p>
            <button onClick={() => window.location.reload()}>Try Again</button>
          </>
        ) : (
          <div className="loading">Evaluating your response...</div>
        )}
      </div>
    );
  }

  return null;
}

