import React from 'react';

export default function DonationBox() {
  return (
    <div className="donation-box">
      <p>Hosting and running this service in cloud costs money. Help keeping this service free by donating:</p>
      <div id="donate-button-container">
        <div id="donate-button"></div>
      </div>  
    </div>
  );
}
