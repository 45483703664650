import React, { useState } from 'react';
import Header from './components/Header';
import Home from './Home';
import About from './About';
import How from './How';
import DonationBox from './components/DonationBox';
import LegalDisclaimer from './components/LegalDisclaimer';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/how-it-works" element={<How />} />
          </Routes>
          <DonationBox />
          <LegalDisclaimer />
        </main>  
      </Router>
      <Footer />
    </div>
  );
}

export default App;
