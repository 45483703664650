import React from 'react';

export default function Footer() {
  return (
    <footer>
        <div className="footer-info">
            <span>© 2024 AI Tech Ventures, LLC</span>
            <span><a class="breadcrumbs" href="./terms_of_use.pdf" target="_blank" rel="noreferrer" download="terms_of_use.pdf">Terms of use</a></span>
            <span><a class="breadcrumbs" href="mailto:mosin@aitechventures.org">Contact us</a></span>
        </div>
    </footer>
  );
}