import React from 'react';

export default function LegalDisclaimer() {
  return (
    <div className="legal-disclaimer">
      <p>
        <strong>Disclaimer:</strong> This website is not affiliated with or endorsed by Educational Testing Service (ETS), the provider of the GRE® General Test. All trademarks, service marks, and other intellectual property related to the GRE® General Test are the exclusive property of ETS. This website is intended solely for practice and informational purposes. The content provided on this site, including but not limited to practice questions and writing prompts, is not sourced from actual GRE® test materials and does not guarantee performance on the GRE® General Test. Users are encouraged to refer to official ETS resources for accurate and up-to-date information. By using this service you are accepting the Terms of Use described in the file by the link below.  
      </p>
    </div>
  );
}
