import React from 'react';

export default function About() {
    return (
        <div>
            <h class="boldtext">About Us</h>
            <p>Welcome a dedicated platform for mastering the Analytical Writing section of the GRE exam. We understand the importance of honing your writing skills to excel in this crucial part of the test, and we are here to provide you with the practice, feedback, and insights you need to succeed.</p>
            <p>Our primary goal is to help you improve your analytical writing abilities by offering a space where you can practice writing essays and receive constructive feedback. Our system is designed to give you a directional score that reflects your current performance level, helping you identify strengths and areas that need further improvement. This personalized approach allows you to focus on developing the skills that will make a significant impact on your overall GRE score.</p>
            <p>Please note that while our scoring system is based on public assessment guidelines, this service is not affiliated with the GRE or ETS (Educational Testing Service). We do not guarantee that the scores you receive through our service will precisely match the results of the actual GRE exam. Our scores are intended to provide guidance and help you gauge your preparedness, but the official GRE scoring is conducted under specific conditions that may differ from our practice environment.</p>
            <p>For more information about the GRE exam, we encourage you to visit the following resources:</p>
            <li><a href="https://www.ets.org/gre.html">GRE Exam Information on the ETS Website</a></li>
            <li><a href="https://www.ets.org/gre/test-takers/general-test/prepare/content/analytical-writing/scoring.html#accordion-06cf390d3c-item-d718e2bb0a">Official GRE Analytical Writing Evaluation Guidelines</a></li>
            <li><a href="https://www.ets.org/pdfs/gre/analytical-writing-pool.pdf">GRE Pool of analytical writing topics</a></li>
            <p>Thank you for using this service in preparing for the GRE Analytical Writing section. Wishing you productive preparation and best of luck on the test!</p>
        </div>
    )
}